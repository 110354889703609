import { useOutdatedRoot } from '@hooks/use-outdated-root';
import { useStore } from '@store/store';
import { cn } from '@utils/cn';
import dayjs from 'dayjs';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Timeout } from 'react-number-format/types/types';
import { SALE_OPENING_TIMESTAMP } from 'src/contants/meld';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const handlePlural = (text: string, value: number) => `${text}${value > 1 ? 's' : ''}`;

const formatCountdown = (ms: number) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;
  const remainingHours = hours % 24;

  let text = ' shortly.';

  if (days > 0 && remainingHours > 0) {
    text = `in ${days} ${handlePlural('day', days)} and ${remainingHours} ${handlePlural('hour', remainingHours)}.`;
  }

  if (days > 0 && remainingHours === 0) {
    text = `in ${days} ${handlePlural('day', days)}.`;
  }

  if (days === 0 && remainingHours > 0 && remainingMinutes > 0) {
    text = `in ${remainingHours} ${handlePlural('hour', remainingHours)} and ${remainingMinutes} ${handlePlural('minute', remainingMinutes)}.`;
  }

  if (days === 0 && remainingHours > 0 && remainingMinutes == 0) {
    text = `in ${remainingHours} ${handlePlural('hour', remainingHours)}`;
  }

  if (days === 0 && remainingHours == 0 && remainingMinutes > 0 && remainingSeconds > 0) {
    text = `in ${remainingMinutes} ${handlePlural('minute', remainingMinutes)} and ${remainingSeconds} ${handlePlural('second', remainingSeconds)}.`;
  }

  if (days === 0 && remainingHours == 0 && remainingMinutes > 0 && remainingSeconds === 0) {
    text = `in ${remainingMinutes} ${handlePlural('minute', remainingMinutes)}.`;
  }

  if (days === 0 && remainingHours == 0 && remainingMinutes === 0 && remainingSeconds > 0) {
    text = `in ${remainingSeconds} ${handlePlural('second', remainingSeconds)}.`;
  }

  return <span className="font-bold text-meldred"> {text}</span>;
};
/**
 * Renders notice that we are updating the whitelist
 */
export const WhitelistMaintenanceBanner = () => {
  const { mismatchingRootNode, saleStopped } = useOutdatedRoot();
  const [textToShow, setTextToShow] = useState<ReactNode>(null);
  const acceptedTerms = useStore((state) => state.data.acceptedTerms);
  const runningLoopRef = useRef(false);

  const countdownDate = useCallback(() => {
    const shouldLoop =
      saleStopped &&
      SALE_OPENING_TIMESTAMP &&
      dayjs.utc(SALE_OPENING_TIMESTAMP * 1000).diff(dayjs.utc(), 'milliseconds');
    setTextToShow(
      <div>
        Whitelisted node sale is not open right now, will open up again{' '}
        {SALE_OPENING_TIMESTAMP ? formatCountdown(shouldLoop as number) : 'the future.'}
      </div>,
    );
    if ((shouldLoop as number) > 0) setTimeout(() => countdownDate(), 1000);
  }, [saleStopped]);

  const countdownDateRef = useRef(countdownDate);

  useEffect(() => {
    countdownDateRef.current = countdownDate;
  }, [countdownDate]);

  useEffect(() => {
    let timeout: Timeout;
    if (saleStopped && !runningLoopRef.current) {
      runningLoopRef.current = true;
      countdownDateRef.current();
      return;
    } else if (mismatchingRootNode) {
      setTextToShow(<>Whitelist reference is being updated, please refresh this page in a couple of minutes.</>);
    } else {
      timeout = setTimeout(() => setTextToShow(''), 1000);
    }

    return () => clearTimeout(timeout);
  }, [saleStopped, mismatchingRootNode, countdownDate]);

  return (
    <div
      className={cn(
        'pointer-events-none fixed left-0 top-0 z-[100000] box-border flex h-0 w-screen items-center justify-center overflow-hidden bg-meldwhite px-4 text-center font-semibold text-[14px] text-meldblack/60 opacity-0 transition-all duration-500 [&_div]:!leading-[18px] ',
        (mismatchingRootNode || saleStopped) && 'h-[50px] opacity-100 md:h-6',
        !acceptedTerms && '!h-0',
      )}
    >
      {textToShow}
    </div>
  );
};
